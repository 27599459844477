<script setup>
import useClients from "@/hooks/useClients";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { reactive, onMounted } from "vue";
import { useToast } from "vue-toastification";
import c3api from "@/c3api";
import { useRouter } from "vue-router";

const carrierTypes =  [{title:'Freight', value: 'freight'}, {title: 'Parcel',value:'parcel'}];
const toast = useToast();
const router = useRouter();

const carrier = reactive({
  name: "",
  carrier_type: "",
  service_code: ""
});

onMounted(async () => {
  setPageTitle("New Carrier");
});

const save = async () => {

  try {
    await c3api.post("/carriers", carrier);
    toast.success("Carrier Added Successfully");
    router.push("/carriers");
  } catch (err) {
    console.error(err);
    toast.error(err.response.data?.data.join(". "));
  }
  
};
</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>

  <v-card flat class="mx-4 mt-4">
    <v-card-text>
      <v-row align="center">
        <v-col cols="2">
          <label for="Name">Name</label>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="carrier.name"
            variant="outlined"
            hide-details="auto"
            placeholder="Name"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Nickname">Carrier Type</label>
        </v-col>
        <v-col cols="10">
          <v-autocomplete
            v-model="carrier.carrier_type"
            :items="carrierTypes"
            variant="outlined"
            hide-details="auto"
            placeholder="Carrier Type"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <label for="Name">Service Code</label>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="carrier.service_code"
            variant="outlined"
            hide-details="auto"
            placeholder="Service Code"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card-actions class="d-flex justify-end mx-2 mt-4">
    <v-btn size="large" variant="flat" color="primary" @click="save"> Save </v-btn>
  </v-card-actions>
</template>
